$light-blue: #E0F1FF;
$light-blue2: #40B5FF;
$dark-blue: #00548A;
$blue: #008FEA;
$blue-2: #309DAD;

$gray:#F5FAFF;
$dark-gray: #3B6970;
$gray-2: #8C9AA7;

$red: #EB7474;

