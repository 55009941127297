@import 'bootstrap/dist/css/bootstrap.min.css';
@import './styles/colors.scss';

@font-face {
    font-family: 'CallunaSans';
    src: url('./assets/CallunaSans-Regular.otf') format('opentype');
}

html {
    height: 100%;
    width: 100%;
}

.home{
    a{
        text-decoration: none; 
        text-align: center;   
    }
}

body {
    height: 100%;
    width: 100%;
    background-color: $light-blue;
}

.content{
    padding: 70px 0px 50px;
    min-height: 75vh;
}

.row{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.btn-blue{
    background: $blue !important;
    color:white;
}

b{
    color: $blue;
}

.file-drop{
    padding:30px;
    background: lightgray;
}

.card-title{
    font-weight: bold;
}

.card-title, .form-label{
    font-family: 'CallunaSans';
    font-weight: bold;
}

p, ol, ul{
    color: $blue;
    font-family: 'CallunaSans';
    font-size: 1.2em;
}

h1{
    color: $blue;
    font-size: 2.8em;
    font-weight: bold;
    font-family: 'CallunaSans';
}

h2{
    color: $dark-blue;
    font-size: 2.0em;
    font-weight: bold;
    font-family: 'CallunaSans';
}

h3{
    color: $dark-blue;
    font-size: 1.7em;
    font-family: 'CallunaSans';
}

strong, b{
    font-weight: bold;
}