@import './colors.scss';

.footer{
    background: $dark-gray;
    padding: 30px;

    p{
        color: white;
        margin: 0px;

        b{
            color:white
        }
    }
}